import { useRouter } from "next/router";
import { useEffect } from "react";

import { Seo } from "@/components/Seo";
import { Spinner } from "@/components/Spinner";

import { pageRoutes } from "@/constant/pageRoutes";

function HomePage() {
  const { replace } = useRouter();

  useEffect(() => {
    replace(pageRoutes.home);
  }, [replace]);

  return (
    <>
      <Seo templateTitle="Home" />
      <Spinner />
    </>
  );
}

export default HomePage;
